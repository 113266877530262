import './App.css';

import logoInvisalign from "./assets/logo_invisalign.png"

function App() {
  return (
      <div className="main-container">
          <div className="header">
              <img src={logoInvisalign} alt={'logo'} className="logo"/>
          </div>
          <iframe
              id="MemzoInt" src="https://memzo.ai/h/8y032h7u/10057/13279"
              allow="camera *"
              style={{border:'none',width:'100vw',height:'80vh',overflowY: 'hidden',overflow:'hidden'}}
              title="Get Your Photos">
          </iframe>

      </div>
  );
}

export default App;
